<template>
    <article class="full-article">
        <a href="#" class="back" @click.prevent="$router.go(-1)">Tillbaka</a>
        <img v-if="article.media.url" :src="article.media.url" />
        <h1>{{article.title}}</h1>
        <p class="ingress" v-if="article.ingress">{{article.ingress}}</p>
        <main class="content" v-html="article.content" />
    </article>
</template>

<script>

export default {
    name: 'fullarticle',
    data(){
        return {
            tellMore: false
        }
    },
    computed: {
        article(){
            return this.$store.getters.getInsprationArticleId(this.$route.params.id*1);
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.full-article {
    background: #eee;

    .back {
        position: absolute;
        background: black;
        top: 1rem;
        left: 1rem;
        z-index: 999;
        font-size: .8rem;
        padding: .25rem;
        border-radius: $radius;
        color: rgba($color: #FFF, $alpha: .8);
        text-decoration: none;
    }

    img {
        width: 100%;
    }

    h1 {
        padding: 1rem;
        line-height: 1.6rem;
    }

    .ingress {
        padding: 1rem;
        margin: 0;
    }

    main {
        padding: 1rem;

        ul {
            list-style: square !important;

            li {
                padding: 0 .75rem;
                margin: 0 0 1rem 0;
                border-left: .25rem solid rgba($color: #000000, $alpha: .2);

                p {
                    margin: 0;
                    padding: 0;
                    font-style: italic;
                    font-size: .8rem;
                }
            }
        }
    }
}
</style>
